'use client';

import { useMemo, useEffect, useRef, useState } from 'react';

import Transcripts from '../transcripts/index.js';

const Video = (props) => {
  const [playVideo, setPlayVideo] = useState(false);

  const videoContainerRef = useRef(null);

  const handleClick = () => {
    setPlayVideo(true);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      setPlayVideo(true);
    }
  };

  useEffect(() => {
    const container = videoContainerRef.current;
    if (container !== null) {
      container.focus();
    }
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const transcriptsRef = useRef(null);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);

    transcriptsRef.current.style.opacity = isExpanded ? '1' : '0';

    setTimeout(() => {
      transcriptsRef.current.style.opacity = isExpanded ? '0' : '1';
    }, 10);
  };

  const transcriptsProps = {
    ...props,
    transcriptsRef,
    isExpanded,
    handleToggle,
  };

  const Iframe = (props) => {
    const { urlInMMC, titleInMMC } = props;
    return (
      <>
        <div
          ref={videoContainerRef}
          className="iframe-container landscape"
          tabIndex={-1}>
          <iframe
            allowFullScreen
            tabIndex="0"
            className="iframe-video absolute top-0 left-0 w-full h-full border-0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            src={urlInMMC}
            title={titleInMMC}
            frameBorder="0"
            scrolling="no"
          />
        </div>
        {props.children}
      </>
    );
  };

  const Placeholder = (props) => {
    const { clickToPlay, titleInMMC, id, children } = props;
    return (
      <div
        className="figure-video m-0 cursor-pointer"
        role="button"
        tabIndex={0}
        aria-label={`${clickToPlay} - ${titleInMMC}`}
        onClick={playVideo ? null : handleClick}
        onKeyDown={playVideo ? null : handleKeyDown}>
        <div className="iframe-container landscape">
          <div className="thumbnail-video-wrapper absolute top-0 left-0 m-0 w-full h-full z-10 transition duration-300 ease-in-out">
            <div className="link-image h-full">
              <div className="thumbnail-video h-full relative">
                <div className="wrapper-picture relative h-full">
                  <div className="player-ico">
                    <div className="arrow" />
                  </div>
                  <span
                    className="background bg-center bg-no-repeat block absolute inset-0"
                    style={{
                      backgroundImage: `url(/assets/img/${id}_placeholder.jpg)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  };

  const videoWidget = useMemo(
    () => (playVideo ? <Iframe {...props} /> : <Placeholder {...props} />),
    [playVideo, props]
  );

  return (
    <>
      {videoWidget}
      <Transcripts {...transcriptsProps} />
    </>
  );
};

export default Video;
