'use client';

import { useMemo } from 'react';

import VideoWidget from '../video/index.js';
import Collapsible from '../collapsible/index.js';

export const kExpandedCollapsibles = [1, 2, 3];

export const updateMeta = ({ property, value }) => {
  const tag = document.querySelector(`meta[property="og:${property}"]`);
  tag.setAttribute('content', value);
};

export const faq =
  (props) =>
  ({ key, title, contents, video }) => {
    const {
      titleSchemaProps,
      contentsSchemaProps,
      expandedCollapsibles,
      currentFAQ,
      faqRef,
      ShareWidget,
    } = props;

    const collapsibleProps = {
      titleSchemaProps,
      contentsSchemaProps,
      collapsibleRef: key === currentFAQ ? faqRef : null,
      expanded: expandedCollapsibles.includes(key),
      title,
    };

    const widgets = useMemo(
      () => (
        <>
          {video ? <VideoWidget {...video} /> : null}
          <ShareWidget title={title} segment={`${key}/`} />
        </>
      ),
      [video, key, title]
    );

    /* eslint-disable react/no-danger */
    return (
      <Collapsible key={key} {...collapsibleProps}>
        <div dangerouslySetInnerHTML={{ __html: contents }} itemProp="text" />
        {widgets}
      </Collapsible>
    );
    /* eslint-enable react/no-danger */
  };

export default faq;
