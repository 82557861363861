'use client';

import { useState } from 'react';

import { kHowToVote } from '../_props/constants.js';

export const menuOption = ({ key, label }) => (
  <option key={key} value={key}>
    {label}
  </option>
);

export const Menus = ({
  chooseACountryLabel,
  chooseALanguageLabel,
  countryLabel,
  languageLabel,
  okButtonLabel,
  selectedCountry,
  selectedLanguage,
  countries,
  countryLanguages,
  handleCountryChange,
  handleLanguageChange,
  handleSubmit,
}) => {
  return (
    <form
      className="lg:flex md:flex sm:block pt-10 pb-8 pl-0 mb-4"
      action=""
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col w-full lg:max-w-[330px] mr-5 mb-4">
        <label
          htmlFor="country-name"
          className="flex items-end flex-1 mb-2 text-lg"
        >
          {chooseACountryLabel}
        </label>
        <div className="relative">
          <select
            required
            aria-label={chooseACountryLabel}
            autoComplete="country-name"
            className="block appearance-none py-3 px-4 pr-8"
            id="country-name"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option disabled value={countryLabel}>
              {countryLabel}
            </option>
            {countries.map(menuOption)}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg
              className="fill-blue-default h-7 w-8"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full lg:max-w-[330px] mr-5 mb-4">
        <label
          htmlFor="language"
          className="flex items-end flex-1 mb-2 text-lg"
        >
          {chooseALanguageLabel}
        </label>
        <div className="relative">
          <select
            required
            aria-label={chooseALanguageLabel}
            autoComplete="language"
            className="block appearance-none py-3 px-4 pr-8"
            id="language"
            value={selectedLanguage}
            disabled={selectedCountry === countryLabel}
            onChange={handleLanguageChange}
          >
            <option disabled value={languageLabel}>
              {languageLabel}
            </option>
            {countryLanguages.map(menuOption)}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg
              className="fill-blue-default h-7 w-8"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex items-end mb-4">
        <button
          className="btn-form text-2xl font-normal"
          type="submit"
          disabled={
            selectedCountry === countryLabel ||
            selectedLanguage === languageLabel
          }
        >
          {okButtonLabel}
        </button>
      </div>
    </form>
  );
};

export const Selector = ({
  chooseACountryLabel,
  chooseALanguageLabel,
  countryLabel,
  languageLabel,
  okButtonLabel,
  countries,
  languages,
}) => {
  const handleCountryChange = (event) => {
    const country = event.target.value;

    setSelectedCountry(country);
    setSelectedLanguage(languageLabel);
    setCountryLanguages(languages[country]);
  };

  const handleLanguageChange = (event) => {
    const language = event.target.value;

    setSelectedLanguage(language);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location = `/${selectedLanguage}/${kHowToVote}/${selectedCountry}/`;
  };

  const [selectedCountry, setSelectedCountry] = useState(countryLabel);
  const [selectedLanguage, setSelectedLanguage] = useState(languageLabel);
  const [countryLanguages, setCountryLanguages] = useState(
    languages[selectedCountry] || []
  );

  const menuProps = {
    chooseACountryLabel,
    chooseALanguageLabel,
    countryLabel,
    languageLabel,
    okButtonLabel,
    selectedCountry,
    selectedLanguage,
    countries,
    countryLanguages,
    handleCountryChange,
    handleLanguageChange,
    handleSubmit,
  };

  return <Menus {...menuProps} />;
};

export default Selector;
