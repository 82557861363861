'use client';

import { useState, useEffect } from 'react';

export const useEnableScrollToTop = () => {
  const [enableScrollToTop, setEnableScrollToTop] = useState(false);

  useEffect(() => {
    const updateEnableScrollToTop = () =>
      setEnableScrollToTop(window.scrollY > window.innerHeight);
    updateEnableScrollToTop();

    window.addEventListener('scroll', updateEnableScrollToTop);

    return () => window.removeEventListener('scroll', updateEnableScrollToTop);
  }, []);

  return [enableScrollToTop];
};

export default useEnableScrollToTop;
