'use client';

import { useState, useEffect } from 'react';

import { kLoading } from '../_props/constants.js';

import {
  consentStatus,
  dnt,
  opted,
  optInFx,
  optOutFx,
  shouldLoadWebAnalytics,
} from './cookies.js';

import WebAnalytics from './webanalytics.js';

export const descriptionLabels = {
  [opted.in]: 'optInDesc',
  [opted.out]: 'optOutDesc',
  [opted.none]: 'chooseDesc',
  [dnt.on]: 'dntDesc',
  [kLoading]: kLoading,
};

export const RadioButtons = ({
  optInLabel,
  optOutLabel,
  handleChange,
  selection,
}) => {
  return (
    <>
      <div className="ep_cookies-form-field">
        <input
          id="a11y-issue-1"
          name="a11y-issues"
          type="radio"
          value={opted.out}
          checked={selection === opted.out}
          onChange={handleChange}
        />
        <label htmlFor="a11y-issue-1">{optOutLabel}</label>
      </div>

      <div className="ep_cookies-form-field">
        <input
          id="a11y-issue-2"
          name="a11y-issues"
          type="radio"
          value={opted.in}
          checked={selection === opted.in}
          onChange={handleChange}
        />
        <label htmlFor="a11y-issue-2">{optInLabel}</label>
      </div>
    </>
  );
};

export const Widget = (props) => {
  const { optInLabel, optOutLabel } = props;

  const [loadWebAnalytics, setLoadWebAnalytics] = useState(false);

  const [selection, setSelection] = useState(kLoading);

  useEffect(() => {
    setSelection(consentStatus());
    setLoadWebAnalytics(shouldLoadWebAnalytics());
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelection(value);
    setLoadWebAnalytics(value === opted.in);
    if (value === opted.in) {
      optInFx();
    } else {
      optOutFx();
    }
  };

  return (
    <>
      <WebAnalytics load={loadWebAnalytics} />
      <form
        id="ep_opt"
        className="ep_opt-form border border-2 border-[LightGray] rounded-md mt-5 mb-54px"
      >
        <fieldset>
          <legend>
            <p>{props[descriptionLabels[selection]]}</p>
          </legend>

          {dnt.on !== selection && (
            <RadioButtons
              {...{
                optInLabel,
                optOutLabel,
                selection,
                handleChange,
              }}
            />
          )}
        </fieldset>
      </form>
    </>
  );
};

export default Widget;
