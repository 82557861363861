'use client';

import { kHowToVote } from '../_props/constants.js';

export const Intro = ({
  lang,
  country,
  pageH1,
  pageChapo,
  lastReviewedOn,
  chooseAllInformation,
  chooseForVotersFromAbroad,
  filterBy,
  opensInSameWindow,
  selectedInfos,
  children,
}) => {
  const handleOnChange = (event) => {
    event.preventDefault();
    const prefix = `/${lang}/${kHowToVote}/${country}/`;

    window.location =
      event.target.value === 'all' ? prefix : `${prefix}abroad/`;
  };

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 mt-54px mb-27px">
        {children}
        <h1 className="font-EPGammaBold text-2.5xl text-black-greydark text-left">
          {pageH1}
        </h1>
      </div>

      <div className="max-w-5xl lg:flex lg:flex-col md:block sm:block block mx-auto px-4 mb-8">
        <div className="flex flex-col w-full lg:max-w-[530px] mr-5 mb-4 border-2 rounded-md border-[LightGray] overflow-hidden">
          <div className="relative">
            <select
              defaultValue={selectedInfos}
              className="block w-full appearance-none bg-white-default text-base py-3 pl-4 pr-8"
              id="voters-info"
              aria-label={filterBy}
              aria-describedby="info-description"
              onChange={handleOnChange}
            >
              <option value="all">{chooseAllInformation}</option>
              <option value="abroad">{chooseForVotersFromAbroad}</option>
            </select>
            <div className="pointer-events-none absolute z-0 inset-y-0 right-0 flex items-center px-2">
              <svg
                className="fill-black-default h-7 w-8"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <span className="hidden" id="info-description">
            {opensInSameWindow}
          </span>
        </div>

        <div itemProp="description">
          <p>{pageChapo}</p>
        </div>
        <p className="mt-2 mb-6">
          <span className="text-base leading-3 inline-block bg-[#edeeee] px-1 py-2">
            {lastReviewedOn}
          </span>
        </p>
      </div>
    </>
  );
};

export default Intro;
