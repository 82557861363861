import { kEmailRegExp, kCountryCodes } from '../_props/constants.js';

export const kValidStatus = [200, 400, 409];
export const kValid = 1;
export const kEmpty = 2;
export const kBadFormat = 3;

export const kSteps = new Set([0, 1, 2, 3]);

export const kEmailStatus = {
  valid: kValid,
  empty: kEmpty,
  badFormat: kBadFormat,
};

export const kCountryStatus = {
  valid: kValid,
  empty: kEmpty,
};

export const kConsentStatus = {
  valid: kValid,
  empty: kEmpty,
};

export const emailStatus = (email) =>
  email.length === 0
    ? kEmailStatus.empty
    : kEmailRegExp.test(email)
      ? kEmailStatus.valid
      : kEmailStatus.badFormat;

export const consentStatus = (consent) =>
  consent ? kConsentStatus.valid : kConsentStatus.empty;

export const countryStatus = (countryLabel, country) =>
  country === countryLabel ? kCountryStatus.empty : kCountryStatus.valid;

export const isValidCountry = (countryLabel, country, countryError) =>
  countryStatus(countryLabel, country) === kCountryStatus.valid &&
  countryError === '';

export const isValidStatus = (status) => kValidStatus.includes(status);

export const buildMessage = (status, json) => {
  console.log(`form-utils::buildMessage from [${JSON.stringify(json)}]`);

  switch (status) {
    case 200: {
      return json.message;
    }

    case 400: {
      return `${json.error.code}: ${json.error.message}`;
    }

    case 409: {
      return `${json.error.code}: ${json.error.message} (referral token: ${json.error.referral_token})`;
    }

    default: {
      return JSON.stringify(json);
    }
  }
};

export const extractFormData = (formData) => {
  const data = {};
  for (const [key, value] of formData) {
    data[key] = value;
  }

  return data;
};

export const readyForSubmission = ({
  email,
  countryLabel,
  selectedCountry,
  consent,
}) =>
  [
    emailStatus(email),
    countryStatus(countryLabel, selectedCountry),
    consentStatus(consent),
  ].every((status) => status === kValid);

export const validateCountry = ({
  countryLabel,
  country,
  setCountryError,
  countryErrorMessage,
}) => {
  switch (countryStatus(countryLabel, country)) {
    case kCountryStatus.empty: {
      setCountryError(countryErrorMessage);
      break;
    }

    default: {
      setCountryError('');
      break;
    }
  }
};

export const validateEmail = ({
  email,
  invalidEmail,
  setEmailError,
  emailErrorMessage,
}) => {
  switch (emailStatus(email)) {
    case kEmailStatus.empty: {
      setEmailError(emailErrorMessage);
      break;
    }

    case kEmailStatus.valid: {
      setEmailError('');
      break;
    }

    default: {
      setEmailError(invalidEmail);
      break;
    }
  }
};

export const validateConsent = ({
  consent,
  setConsentError,
  dpnErrorMessage,
}) => {
  switch (consentStatus(consent)) {
    case kConsentStatus.valid: {
      setConsentError('');
      break;
    }

    default: {
      setConsentError(dpnErrorMessage);
      break;
    }
  }
};

export const parseEmail = (searchParameters) => {
  const email =
    searchParameters.get('email')?.slice(0, 128).replaceAll(' ', '+') || '';
  return kEmailRegExp.test(email) ? email : null;
};

export const parseStep = (searchParameters) => {
  const step = Number.parseInt(searchParameters.get('step')?.slice(0, 1), 10);
  return !Number.isNaN(step) && kSteps.has(step) ? step : null;
};

export const parseMessageCode = (searchParameters, validCodes) => {
  const code = Number.parseInt(searchParameters.get('code')?.slice(0, 1), 10);
  return !Number.isNaN(code) && validCodes.has(code) ? code : Number.NaN;
};

export const parseCountryCode = (searchParameters) => {
  const countryCode = searchParameters.get('country')?.slice(0, 2) || '';
  return kCountryCodes.has(countryCode.toLowerCase())
    ? countryCode.toUpperCase()
    : null;
};

export const kBackends = {
  test: 'test.together.eu',
  dev: 'dev.together.eu',
  prod: 'together.europarl.europa.eu',
  pp: 'together.europarl.europa.eu',
};

export const serverForHostname = (hostname) => {
  if (hostname === 'elections.europa.eu') {
    return kBackends.prod;
  }

  // default to test.together.eu
  return kBackends.dev;
};

export const serverURL = ({ hostname, lang, api }) => {
  const segment = lang === 'en' ? '' : `${lang}/`;
  const server = serverForHostname(hostname);

  console.log(`serverURL:: hostname: ${hostname}, server: ${server}`);
  return `https://${server}/${segment}api/pledge/${api}`;
};
