import { useState, useEffect, useRef } from 'react';

import { serverURL } from './form-utils.js';

export const kAPI = 'unsubscribe';
export const kValidStatus = [200, 409];
export const kStatus = {
  loading: 'loading',
  ok: 'ok',
  ko: 'ko',
  invalid: 'invalid',
  iddle: 'iddle',
};

/* eslint-disable react/no-danger */
export const TogetherMessage = ({ togetherMessage }) => (
  <div
    dangerouslySetInnerHTML={{ __html: togetherMessage }}
    className="max-w-[550px]"
  />
);
/* eslint-enable react/no-danger */

export const Unsubscribe = ({
  lang,
  email,
  token,
  unsubscribeLabel,
  unsubscribeText,
  unsubscribedText,
  loadingMessage,
  invalidSubscription,
  somethingWentWrong,
  togetherMessage,
  togetherLogoLabel,
}) => {
  const [status, setStatus] = useState(kStatus.iddle);
  const messages = {
    [kStatus.iddle]: unsubscribeText,
    [kStatus.ok]: unsubscribedText,
    [kStatus.ko]: somethingWentWrong,
    [kStatus.invalid]: invalidSubscription,
    [kStatus.loading]: loadingMessage,
  };

  const messageRef = useRef(null);
  useEffect(() => {
    if (![kStatus.iddle, kStatus.loading].includes(status)) {
      messageRef.current.focus();
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [status]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { hostname } = new URL(window.location.href);
    const formData = new FormData(event.target);

    const request = {
      redirect: 'follow',
      method: 'POST',
      body: formData,
    };

    setStatus(kStatus.loading);
    const response = await fetch(
      serverURL({ api: kAPI, hostname, lang }),
      request
    );
    const payload = kValidStatus.includes(response.status)
      ? await response.json()
      : { message: response.statusText };

    console.log(
      `[submit]: status: ${response.status}, msg: ${payload.message}`
    );

    switch (response.status) {
      case 200: {
        setStatus(kStatus.ok);
        break;
      }

      case 409: {
        setStatus(kStatus.invalid);
        break;
      }

      default: {
        // something went wrong
        setStatus(kStatus.ko);
        break;
      }
    }
  };

  return (
    <>
      <div className="uyv-banner bg-blue-default flex py-20">
        <div className="uyv-share w-full flex flex-col self-center mx-auto max-w-5xl mx-auto px-4">
          <h1
            ref={messageRef}
            id="message"
            tabIndex="-1"
            className="font-EPGammaBold text-yellow-active text-3lg leading-10 mb-4">
            {messages[status]}
          </h1>
          <div className="sr-only" role="status">
            {status === kStatus.loading ? messages[status] : ''}
          </div>
          {status === kStatus.iddle ? (
            <form action="" onSubmit={handleSubmit}>
              <input name="email" value={email} type="hidden" />
              <input name="token" value={token} type="hidden" />
              <div className="block md:flex copy-block mb-2">
                <div className="relative mb-2 w-full">
                  <input
                    readOnly
                    aria-labelledby="message"
                    type="text"
                    className="copy-target text-[1rem] sm:text-[1.25rem] block min-w-[220px] sm:min-w-none min-h-[60px] w-full border-0 bg-white-default px-4 py-1"
                    id="copy-target"
                    value={email}
                  />
                </div>
                <div>
                  <button
                    id="unsubscribe-button"
                    type="submit"
                    className="copy-button inline-block bg-yellow-default text-blue-default rounded-r-lg rounded-l-lg md:rounded-l-none min-h-[60px] px-10 pt-1.5 pb-0.5 uppercase leading-normal text-white transition duration-150 ease-in-out">
                    {unsubscribeLabel}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="tg-info-block max-w-5xl mx-auto mt-20 mb-0 px-4">
        <img
          src={`/assets/img/logos/logo-together-blue-${lang}.svg`}
          alt={togetherLogoLabel}
          className="max-w-[190px] mb-6"
        />
        <TogetherMessage togetherMessage={togetherMessage} />
      </div>
    </>
  );
};

export default Unsubscribe;
