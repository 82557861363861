'use client';

import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

const DynamicStreamingPlayer = ({ lang, videoConfig, videoTitle }) => {
  const [player, setPlayer] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await fetch(`/${videoConfig}`);
      if (!response.ok) {
        console.log('!!! streaming: Failed to fetch config file');
        return;
      }

      const text = await response.text();
      const [type, url] = text.trim().split(' ');

      if (!['a', 'n', 'e'].includes(type) || !url) {
        console.log('!!! streaming: Invalid config file format');
        return;
      }

      setPlayer(type);
      setVideoUrl(url);
    };

    fetchConfig();
  }, [videoConfig]);

  const StreamingPlayer = dynamic(() => {
    switch (player) {
      case 'a': {
        return import('./arbor.jsx');
      }

      case 'n': {
        return import('./nimbllr.jsx');
      }

      case 'e': {
        return import('./ebs.jsx');
      }

      default: {
        return null;
      }
    }
  });

  return player && videoUrl ? (
    <>
      <p>
        <em>
          <strong>{videoTitle}</strong>
        </em>
      </p>
      <div className="figure-video my-6 mx-0 cursor-pointer">
        <div className="iframe-container landscape relative">
          <div className="thumbnail-video-wrapper absolute top-0 left-0 m-0 w-full h-full z-10 transition duration-300">
            <StreamingPlayer
              language={lang}
              title={videoTitle}
              url={videoUrl}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default DynamicStreamingPlayer;
