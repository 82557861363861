'use client';

import { useSearchParams } from 'next/navigation';

import UnsubscribePage from './unsubscribe-page.js';
import ThankyouPage from './thankyou-page.js';

import { parseEmail, parseStep } from './form-utils.js';

export const PageDispatcher = ({ thankyouProps, unsubscribeFormProps }) => {
  const searchParameters = useSearchParams();

  const step = parseStep(searchParameters);
  const email = parseEmail(searchParameters) || '';
  const token = searchParameters.get('token')?.slice(0, 16) || '';

  return step === 0 && token.length === 16 && email ? (
    <UnsubscribePage {...{ ...unsubscribeFormProps, token, email }} />
  ) : (
    <ThankyouPage {...thankyouProps} />
  );
};

export default PageDispatcher;
