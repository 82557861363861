export const socialNetwork = 'twitter';

export const linker = ({ url, message, twitterVia }) =>
  `https://twitter.com/intent/tweet?text=${message}&url=${url}&via=${twitterVia}`;

export const ShareOnTwitter = ({
  url,
  title,
  hashtag,
  shareOn,
  twitterVia,
}) => {
  const message = `${title}${hashtag ? ` ${hashtag}` : ''}`;
  const share = `${shareOn(socialNetwork)} - ${message}`;

  return (
    <a
      href={linker({
        twitterVia: encodeURIComponent(twitterVia),
        url: encodeURIComponent(url),
        message: encodeURIComponent(message),
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="sr-only">{share}</span>
      <svg
        aria-hidden
        className="twitter w-[34px] h-[34px] p-[5px]"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 17.21 15.09"
        width="17.21"
        height="15.09"
      >
        <g>
          <path
            className="icon"
            d="M16.84-0.24l-1.05,1.13l-1.93,2.07c-0.75,0.81-1.51,1.62-2.26,2.42c-0.21,0.23-0.43,0.45-0.64,0.67
                            c-0.06,0.06-0.08,0.11-0.02,0.19c0.37,0.51,0.74,1.03,1.11,1.55c0.36,0.5,0.72,0.99,1.07,1.49c0.41,0.58,0.82,1.17,1.24,1.75
                            c0.48,0.67,0.97,1.34,1.45,2.01c0.29,0.4,0.56,0.8,0.84,1.21c0.19,0.27,0.39,0.55,0.58,0.82c0.02,0.03,0.03,0.07,0.04,0.11
                            c-0.04,0.02-0.08,0.03-0.12,0.04c-0.22,0-0.44,0-0.66,0c-1.68,0-3.35,0-5.03,0c-0.14,0.01-0.28-0.06-0.36-0.18
                            c-0.43-0.61-0.86-1.21-1.29-1.81c-0.35-0.49-0.71-0.97-1.05-1.46s-0.7-1.01-1.06-1.51c-0.04-0.06-0.1-0.1-0.14-0.16
                            c-0.18-0.26-0.17-0.25-0.38-0.03c-0.3,0.33-0.61,0.65-0.92,0.97c-0.29,0.31-0.58,0.62-0.87,0.93l-1.93,2.08
                            c-0.34,0.36-0.67,0.72-1.01,1.08c-0.06,0.05-0.13,0.08-0.21,0.09c-0.64,0.01-1.27,0-1.91,0c-0.05-0.01-0.09-0.02-0.13-0.04
                            c0.01-0.04,0.03-0.08,0.06-0.12c0.77-0.83,1.55-1.66,2.32-2.48c0.94-1,1.89-2.01,2.83-3.01c0.3-0.32,0.61-0.65,0.91-0.98
                            C6.37,8.57,6.38,8.5,6.35,8.44c0-0.01-0.01-0.01-0.02-0.02C5.89,7.82,5.45,7.2,5.01,6.59C4.59,6,4.17,5.4,3.74,4.8
                            c-0.37-0.51-0.73-1.02-1.1-1.53c-0.29-0.4-0.57-0.81-0.86-1.22S1.2,1.25,0.91,0.84C0.67,0.51,0.44,0.18,0.2-0.15
                            C0.14-0.24,0.18-0.27,0.27-0.27c0.11-0.01,0.22,0,0.32,0c1.83,0,3.65,0,5.48,0C6.21-0.29,6.33-0.22,6.4-0.1
                            c0.22,0.32,0.45,0.62,0.67,0.94c0.24,0.33,0.46,0.67,0.7,1c0.28,0.4,0.58,0.79,0.86,1.19s0.59,0.83,0.88,1.24
                            c0.09,0.13,0.19,0.24,0.27,0.37s0.13,0.1,0.22,0.01c0.44-0.48,0.89-0.96,1.34-1.44c0.52-0.56,1.05-1.12,1.57-1.68
                            c0.42-0.45,0.82-0.9,1.24-1.35c0.12-0.13,0.25-0.26,0.39-0.39c0.05-0.04,0.1-0.06,0.16-0.06c0.66,0,1.31,0,1.97,0
                            C16.73-0.26,16.79-0.25,16.84-0.24 M14.16,13.61c0-0.03-0.01-0.05-0.02-0.08c-0.25-0.35-0.49-0.7-0.74-1.05
                            c-0.36-0.5-0.73-1-1.09-1.51s-0.69-1-1.05-1.49c-0.29-0.41-0.6-0.82-0.9-1.23c-0.35-0.48-0.68-0.97-1.03-1.46
                            c-0.5-0.7-1.02-1.4-1.52-2.1c-0.29-0.4-0.57-0.81-0.86-1.22C6.5,2.85,6.05,2.22,5.6,1.59C5.53,1.45,5.39,1.37,5.24,1.37
                            c-0.6,0.01-1.21,0-1.81,0c-0.04,0-0.09,0.01-0.13,0.02c0.07,0.11,0.13,0.2,0.19,0.29c0.3,0.41,0.6,0.81,0.89,1.22
                            C4.75,3.4,5.1,3.92,5.47,4.42c0.3,0.42,0.61,0.84,0.91,1.26s0.61,0.85,0.91,1.27s0.59,0.82,0.89,1.24c0.36,0.5,0.72,1,1.07,1.5
                            c0.3,0.42,0.61,0.84,0.91,1.26c0.44,0.61,0.88,1.22,1.31,1.83c0.17,0.23,0.33,0.47,0.51,0.7c0.05,0.07,0.13,0.11,0.21,0.12
                            c0.49,0.01,0.98,0.01,1.47,0.01L14.16,13.61"
          />
        </g>
      </svg>
    </a>
  );
};

export default ShareOnTwitter;
