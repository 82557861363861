'use client';

import Collapsible from '../collapsible/index.js';

export const updateMeta = ({ property, value }) => {
  const tag = document.querySelector(`meta[property="og:${property}"]`);
  tag.setAttribute('content', value);
};

export const faq =
  (props) =>
  ({ key, title, contents, hasMicrodata }) => {
    const {
      titleSchemaProps,
      contentsSchemaProps,
      expandedCollapsibles,
      currentFAQ,
      faqRef,
      ShareWidget,
    } = props;

    const microDataProps = hasMicrodata
      ? { titleSchemaProps, contentsSchemaProps }
      : {};

    const collapsibleProps = {
      ...microDataProps,
      collapsibleRef: key === currentFAQ ? faqRef : null,
      expanded: expandedCollapsibles.includes(key),
      title,
    };

    const widgets = <ShareWidget title={title} segment={`${key}/`} />;

    /* eslint-disable react/no-danger */
    return (
      <Collapsible key={key} {...collapsibleProps}>
        <div dangerouslySetInnerHTML={{ __html: contents }} itemProp="text" />
        {widgets}
      </Collapsible>
    );
    /* eslint-enable react/no-danger */
  };

export default faq;
