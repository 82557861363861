'use client';

import { useRef, useEffect } from 'react';

import {
  kUrlPrefix,
  kHowToVote,
  kAbroad,
  xAccount,
} from '../_props/constants.js';

import Collapsible, { Collapsibles } from '../collapsible/index.js';
import shareWidgetBuilder from '../share-widget/index.js';

export const isFAQPage = (currentFAQ) => currentFAQ !== null;

export const faq =
  (props) =>
  ({ key, title, longTitle, contents, microdata }) => {
    const {
      titleSchemaProps,
      contentsSchemaProps,
      expandedCollapsibles,
      currentFAQ,
      faqRef,
      ShareWidget,
    } = props;

    const microDataProps = microdata
      ? { titleSchemaProps, contentsSchemaProps }
      : {};

    const collapsibleProps = {
      ...microDataProps,
      collapsibleRef: key === currentFAQ ? faqRef : null,
      expanded: expandedCollapsibles.includes(key),
      title,
    };

    const widgets = <ShareWidget title={longTitle} segment={`${key}/`} />;

    /* eslint-disable react/no-danger */
    return (
      <Collapsible key={key} {...collapsibleProps}>
        <div dangerouslySetInnerHTML={{ __html: contents }} itemProp="text" />
        {widgets}
      </Collapsible>
    );
    /* eslint-enable react/no-danger */
  };

export const FAQs = ({
  lang,
  country,
  shareLabel,
  socialNetworkLabels,
  copyURLProps,
  titleSchemaProps,
  contentsSchemaProps,
  faqsProps,
  expandedFAQs,
  currentFAQ,
  isAbroad,
}) => {
  const ShareWidget = shareWidgetBuilder({
    baseUrl: `${kUrlPrefix}/${lang}/${kHowToVote}/${country}/${
      isAbroad ? `${kAbroad}/` : ''
    }`,
    twitterVia: xAccount(country || lang),
    shareLabel,
    socialNetworkLabels,
    copyURLProps,
  });

  const faqRef = useRef(null);

  useEffect(() => {
    if (isFAQPage(currentFAQ)) {
      faqRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });

      faqRef.current.querySelector('button').focus();
    }
  }, [currentFAQ]);

  return (
    <Collapsibles>
      {faqsProps.map(
        faq({
          titleSchemaProps,
          contentsSchemaProps,
          expandedCollapsibles: isFAQPage(currentFAQ)
            ? [currentFAQ]
            : expandedFAQs,
          currentFAQ,
          faqRef,
          ShareWidget,
        })
      )}
    </Collapsibles>
  );
};

export default FAQs;
