'use client';

import { useId } from 'react';

export const Transcripts = (props) => {
  const {
    transcriptsRef,
    isExpanded,
    handleToggle,
    textTermTranscript,
    titleInMMC,
    text,
  } = props;
  const transcripts = text.map((item, index) => ({ key: index, text: item }));

  const transcriptItem = useId();

  return (
    <>
      <div className="figcaption relative block w-full">
        <span className="text-xl font-EPGammaBold block text-black-default py-4 transition duration-300 transform">
          {titleInMMC}
        </span>
      </div>
      <div className="expand-container mb-4" data-initial-state="collapsed">
        <button
          type="button"
          className="transcript-btn expand-button pb-1"
          aria-expanded={isExpanded}
          aria-controls={transcriptItem}
          onClick={handleToggle}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 10 9">
            <rect className="vert" height="8" x="4.5" y="0.5" width="0.8" />
            <rect width="8" y="4" x="1" height="0.8" />
          </svg>
          <span className="text-lg text-blue-default underline">
            {textTermTranscript} <span className="sr-only">- {titleInMMC}</span>
          </span>
        </button>
        <div
          ref={transcriptsRef}
          id={transcriptItem}
          className={`expand-container-content${isExpanded ? '' : ' hidden'}`}>
          <div className="mt-6">
            {transcripts.map(({ key, text }) => (
              <p key={key}>{text}</p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transcripts;
