import { Fragment } from 'react';

export const Thankyou = ({
  pageH1,
  pageText1,
  pageText2,
  pageText3,
  pageText4,
}) => {
  const markup = {
    __html: [pageText1, pageText2, pageText3, pageText4].join('\n'),
  };

  return (
    <div className="remind-page">
      <div className="bg-blue-default flex py-20">
        <div className="remind-block flex flex-col mx-auto max-w-5xl mx-auto px-0">
          <div className="px-4">
            <h1>{pageH1}</h1>
            <div
              dangerouslySetInnerHTML={markup} // eslint-disable-line react/no-danger
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
