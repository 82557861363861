'use client';

import { useId, useRef, useState } from 'react';

export const Collapsible = (props) => {
  const {
    titleSchemaProps = {},
    contentsSchemaProps = {},
    collapsibleRef = null,
    expanded = false,
    title,
    children,
  } = props;

  const qaItem = useId();
  const [isExpanded, setIsExpanded] = useState(expanded);
  const contentRef = useRef(null);
  const toggleItem = () => {
    setIsExpanded(!isExpanded);

    contentRef.current.style.opacity = isExpanded ? '1' : '0';

    setTimeout(() => {
      contentRef.current.style.opacity = isExpanded ? '0' : '1';
    }, 10);
  };

  return (
    <li
      ref={collapsibleRef}
      className="expand-container mb-6"
      {...titleSchemaProps}>
      <h2 className="block mb-6">
        <button
          type="button"
          itemProp="name"
          className="expand-button w-full text-left flex justify-between items-center py-2 hover:underline hover:decoration-1 hover:decoration-blue-default hover:underline-offset-4 focus:outline focus:outline-2 focus:outline-blue-focus focus:outline-offset-4 group"
          aria-expanded={isExpanded}
          aria-controls={qaItem}
          onClick={toggleItem}>
          <span className="expand-title font-EPGammaBold">{title}</span>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 10 9">
            <rect className="vert" height="8" x="4.5" y="0.5" width="0.8" />
            <rect width="8" y="4" x="1" height="0.8" />
          </svg>
        </button>
      </h2>
      <div
        ref={contentRef}
        id={qaItem}
        className={`expand-container-content pb-8${
          isExpanded ? '' : ' hidden'
        }`}
        {...contentsSchemaProps}>
        {children}
      </div>
    </li>
  );
};

export const Collapsibles = ({ children }) => (
  <div className="max-w-5xl lg:flex md:block sm:block block mx-auto justify-between items-center px-4 mb-72px">
    <ul className="expand-collapse max-w-5xl w-full [&>li]:border-b-2 [&>li]:border-black-greylight [&>li:last-of-type]:border-none">
      {children}
    </ul>
  </div>
);

export default Collapsible;
