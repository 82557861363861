'use client';

import { useRef, useEffect } from 'react';

import { kUrlPrefix, xAccount } from '../_props/constants.js';
import shareWidgetBuilder from '../share-widget/index.js';
import { Collapsibles } from '../collapsible/index.js';

export const isFAQPage = (currentFAQ) => currentFAQ !== null;

export const FAQs = ({
  lang,
  pageSegment,
  shareLabel,
  socialNetworkLabels,
  copyURLProps,
  titleSchemaProps,
  contentsSchemaProps,
  currentFAQ,
  expandedCollapsibles,
  faq,
  faqsProps,
}) => {
  const ShareWidget = shareWidgetBuilder({
    baseUrl: `${kUrlPrefix}/${lang}/${pageSegment}/`,
    twitterVia: xAccount(lang),
    shareLabel,
    socialNetworkLabels,
    copyURLProps,
  });

  // console.log(`>>> faqs:: currentFAQ: ${currentFAQ}`);

  const faqRef = useRef(null);

  useEffect(() => {
    if (isFAQPage(currentFAQ)) {
      faqRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });

      faqRef.current.querySelector('button').focus();
    }
  }, [currentFAQ]);

  return (
    <Collapsibles>
      {faqsProps.map(
        faq({
          titleSchemaProps,
          contentsSchemaProps,
          expandedCollapsibles: isFAQPage(currentFAQ)
            ? [currentFAQ]
            : expandedCollapsibles,
          currentFAQ,
          faqRef,
          ShareWidget,
        })
      )}
    </Collapsibles>
  );
};

export default FAQs;
