'use client';

import useEnableScrollToTop from '../_hooks/enable-scroll-to-top.js';

export const mainID = '#mainNav';
export const scrollThreshold = 860;

export const ScrollToTop = ({ backToTop }) => {
  const [enableScrollToTop] = useEnableScrollToTop();

  const handleClick = (event) => {
    event.preventDefault();
    document.querySelector(mainID).focus();
    document.querySelector(mainID).scrollIntoView({
      behavior: 'smooth',
      // block: 'nearest',
      // inline: 'center',
    });
  };

  return (
    <a
      href={mainID}
      className={`top-link js-top ${enableScrollToTop ? 'show' : 'hide'}`}
      aria-label={backToTop}
      onClick={handleClick}
    />
  );
};

export default ScrollToTop;
